*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(51 154 240 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(51 154 240 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
.pointer-events-none {
    pointer-events: none;
}
.pointer-events-auto {
    pointer-events: auto;
}
.fixed {
    position: fixed;
}
.left-0 {
    left: 0px;
}
.right-10 {
    right: 2.5rem;
}
.top-0 {
    top: 0px;
}
.top-1\/2 {
    top: 50%;
}
.z-50 {
    z-index: 50;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.my-md {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.my-xl {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.mb-10 {
    margin-bottom: 2.5rem;
}
.mb-36 {
    margin-bottom: 9rem;
}
.mb-xl {
    margin-bottom: 1.5rem;
}
.ml-64 {
    margin-left: 16rem;
}
.ml-md {
    margin-left: 1rem;
}
.mt-10 {
    margin-top: 2.5rem;
}
.mt-20 {
    margin-top: 5rem;
}
.mt-32 {
    margin-top: 8rem;
}
.mt-48 {
    margin-top: 12rem;
}
.mt-xl {
    margin-top: 1.5rem;
}
.block {
    display: block;
}
.flex {
    display: flex;
}
.grid {
    display: grid;
}
.\!hidden {
    display: none !important;
}
.hidden {
    display: none;
}
.aspect-square {
    aspect-ratio: 1 / 1;
}
.h-9 {
    height: 2.25rem;
}
.h-screen {
    height: 100vh;
}
.min-h-screen {
    min-height: 100vh;
}
.w-56 {
    width: 14rem;
}
.w-auto {
    width: auto;
}
.w-full {
    width: 100%;
}
.w-screen {
    width: 100vw;
}
.min-w-\[10rem\] {
    min-width: 10rem;
}
.min-w-\[12rem\] {
    min-width: 12rem;
}
.min-w-\[20rem\] {
    min-width: 20rem;
}
.max-w-2xl {
    max-width: 42rem;
}
.max-w-7xl {
    max-width: 80rem;
}
.max-w-\[20rem\] {
    max-width: 20rem;
}
.max-w-xl {
    max-width: 36rem;
}
.flex-1 {
    flex: 1 1 0%;
}
.shrink-0 {
    flex-shrink: 0;
}
.cursor-pointer {
    cursor: pointer;
}
.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.flex-col {
    flex-direction: column;
}
.items-start {
    align-items: flex-start;
}
.items-center {
    align-items: center;
}
.justify-end {
    justify-content: flex-end;
}
.justify-between {
    justify-content: space-between;
}
.gap-1 {
    gap: 0.25rem;
}
.gap-10 {
    gap: 2.5rem;
}
.gap-2 {
    gap: 0.5rem;
}
.gap-20 {
    gap: 5rem;
}
.gap-lg {
    gap: 1.25rem;
}
.gap-xl {
    gap: 1.5rem;
}
.gap-xs {
    gap: 0.625rem;
}
.whitespace-pre-wrap {
    white-space: pre-wrap;
}
.rounded-full {
    border-radius: 9999px;
}
.rounded-md {
    border-radius: 0.5rem;
}
.rounded-sm {
    border-radius: 0.25rem;
}
.bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(34 139 230 / var(--tw-bg-opacity));
}
.bg-primary-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(231 245 255 / var(--tw-bg-opacity));
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.p-10 {
    padding: 2.5rem;
}
.p-md {
    padding: 1rem;
}
.p-xl {
    padding: 1.5rem;
}
.px-md {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-sm {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-md {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-xs {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}
.pl-xl {
    padding-left: 1.5rem;
}
.pr-xs {
    padding-right: 0.625rem;
}
.text-center {
    text-align: center;
}
.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}
.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
.text-lg {
    font-size: 1.125rem;
}
.text-sm {
    font-size: 0.875rem;
}
.text-xl {
    font-size: 1.25rem;
}
.text-xs {
    font-size: 0.75rem;
}
.font-bold {
    font-weight: 700;
}
.font-medium {
    font-weight: 500;
}
.font-normal {
    font-weight: 400;
}
.text-dark {
    --tw-text-opacity: 1;
    color: rgb(37 38 43 / var(--tw-text-opacity));
}
.text-gray {
    --tw-text-opacity: 1;
    color: rgb(134 142 150 / var(--tw-text-opacity));
}
.text-green {
    --tw-text-opacity: 1;
    color: rgb(64 192 87 / var(--tw-text-opacity));
}
.text-primary-600 {
    --tw-text-opacity: 1;
    color: rgb(34 139 230 / var(--tw-text-opacity));
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.no-underline {
    text-decoration-line: none;
}
.shadow {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xs {
    --tw-shadow: 0 2px 3px -1px rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 2px 3px -1px var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline {
    outline-style: solid;
}
.outline-2 {
    outline-width: 2px;
}
.outline-primary {
    outline-color: #228be6;
}
.blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: var(--mantine-font-family);
    scroll-behavior: smooth;
}

.hover\:scale-102:hover {
    --tw-scale-x: 1.02;
    --tw-scale-y: 1.02;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(241 243 245 / var(--tw-bg-opacity));
}

.hover\:bg-primary-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(208 235 255 / var(--tw-bg-opacity));
}

.hover\:bg-primary-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(51 154 240 / var(--tw-bg-opacity));
}

.hover\:bg-primary-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(28 126 214 / var(--tw-bg-opacity));
}

.hover\:shadow-sm:hover {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:outline-2:hover {
    outline-width: 2px;
}

@media (min-width: 768px) {
    .md\:absolute {
        position: absolute;
    }
    .md\:relative {
        position: relative;
    }
    .md\:mt-0 {
        margin-top: 0px;
    }
    .md\:block {
        display: block;
    }
    .md\:-translate-y-1\/2 {
        --tw-translate-y: -50%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .md\:text-center {
        text-align: center;
    }
    .md\:text-2xl {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .md\:text-5xl {
        font-size: 3rem;
        line-height: 1;
    }
}
